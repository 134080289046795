export const jsonDataFirefox = {
  code: 200,
  message: 'OK',
  data: {
    bookmarks: [
      {
        browser_name: 'Firefox',
        url: 'https://www.youtube.com/',
      },
      {
        browser_name: 'Firefox',
        url: 'https://kik.com/',
      },
      {
        browser_name: 'Firefox',
        url: 'https://www.tumblr.com/',
      },
    ],
    total: 3,
    update_time: 1700116735,
  },
};

export const jsonDataGoogle = {
  code: 200,
  message: 'OK',
  data: {
    bookmarks: [
      {
        browser_name: 'Google Chrome',
        url: 'https://www.reddit.com/',
      },
      {
        browser_name: 'Google Chrome',
        url: 'https://yikyak.com/',
      },
      {
        browser_name: 'Google Chrome',
        url: 'https://twitter.com/',
      },
    ],
    total: 3,
    update_time: 1700116735,
  },
};

export const jsonDataEdge = {
  code: 200,
  message: 'OK',
  data: {
    bookmarks: [
      {
        browser_name: 'Microsoft Edge',
        url: 'https://ask.fm/',
      },
      {
        browser_name: 'Microsoft Edge',
        url: 'https://www.tiktok.com/',
      },
      {
        browser_name: 'Microsoft Edge',
        url: 'https://www.omegle.com/',
      },
    ],
    total: 3,
    update_time: 1700116735,
  },
};
