export const jsonData = {
  code: 200,
  message: 'OK',
  data: {
    browser_list: [
      {
        browser_name: 'Firefox',
        icon_url:
          'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo/images/icon-firefox.png',
      },
      {
        browser_name: 'Google Chrome',
        icon_url:
          'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo/images/icon-google-chrome.png',
      },
      {
        browser_name: 'Microsoft Edge',
        icon_url:
          'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo/images/icon-microsoft-edge.png',
      },
    ],
  },
};
