import axios from 'axios';
import store from '@/store';
import { service } from '@/utils/request';

// 获取浏览器列表
export const getBrowser = (data: any) => {
  return service({
    url: '/v1/browsers',
    method: 'get',
    params: data,
  });
};

// 获取历史记录
export const getHistory = (data: any) => {
  return service({
    url: '/v1/browser/history',
    method: 'get',
    params: data,
  });
};

// 获取书签列表
export const getBookmark = (data: any) => {
  return service({
    url: '/v1/browser/bookmarks',
    method: 'get',
    params: data,
  });
};

// 获取网站限制规则
export const getBrowserLimit = (data: any) => {
  return service({
    url: '/v1/browser/limiters',
    method: 'get',
    params: data,
  });
};

// 添加网站限制规则
export const addLimit = (data: any) => {
  return service({
    url: '/v1/browser/limiters',
    method: 'post',
    data,
  });
};

// 移除网站限制规则
export const removeLimit = (data: any) => {
  return service({
    url: '/v1/browser/limiters',
    method: 'delete',
    data,
  });
};

// 修改网站预警邮件状态
export const editNotify = (data: any) => {
  return service({
    url: '/v1/browser/limit/notification',
    method: 'patch',
    data,
  });
};
